import logo from './topswe-logo.png';
import './App.css';
import BasicTable from './Table';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Instagram, YouTube} from "@mui/icons-material"
import {IconButton}from '@mui/material';
import BookIcon from '@mui/icons-material/Book';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <br></br>
        <Typography variant="h1" gutterBottom>
        TopSWE 100
      </Typography>
      <Typography variant="h5" gutterBottom>
        Solve these 100 carefully curated problems in order! See you at the top!
        {/*Compressed 2500 problems into 100 - see you at the top!*/}
        {/*Solve these 100 problems in order - see you there?*/}
      {/*Solve these 100 problems in order - to land your dream job!*/}
      </Typography>
      <Container align='center'>
        
        <IconButton href="https://www.tiktok.com/@top.swe" title = 'TikTok'>
        <TikTokIcon/>
        </IconButton>

        <IconButton href="https://www.youtube.com/@topswe" title = 'YouTube'>
        <YouTube style={{ fontSize: 60, color: 'red'}}/>
        </IconButton>

        <IconButton href="https://www.instagram.com/top.swe" title = 'Instagram'>
        <Instagram style={{ fontSize: 60, color: 'purple'}}/>
        </IconButton>

        <IconButton href="https://medium.com/@topswebusiness" title = 'Medium'>
        <BookIcon style={{ fontSize: 60, color: 'white'}}/>
        </IconButton>

      </Container>

        <BasicTable></BasicTable>

      <Typography variant="h3" gutterBottom>
        Congratulations!
      </Typography>
      </header>

    </div>
  );
}

const TikTokIcon = ({ color = "pink" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="60px"
      height="60px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

export default App;

